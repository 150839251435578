import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button,Form,Row,Col,Nav,Tab,Tabs,TabContainer,TabContent,TabPane,Spinner} from "react-bootstrap";
import {toast } from 'react-toastify';

import { signIn } from "../../redux/actions/authActions";
import { useAuth } from "../../providers/use-auth";
import axios from "axios";

import { ResetPasswordContainer } from "./style";
const loginbg = "https://dashboard.tap1ce.com/media/login-bg.jpg";
const loginBanner = "https://dashboard.tap1ce.com/media/login-banner.jpg";

const ResetPasswordPage = ({from,match, history}) => {
  const dispatch = useDispatch();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");

  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);


  useEffect(() => {

	console.log("match resetToken - "+match.params.resetToken);

	if(match.params.resetToken){
		//console.log("match resetToken found");
	}else{
		//console.log("match resetToken not found");
		history.push(`/`);
	}

  }, [ match]);

 
  const onResetPassword = async (e) => {

	e.preventDefault();
	setProgress(true);

	if(password == ""){
		setProgress(false);
		return toast.warn("Please enter your new password!");
	}else if(password.length < 6){
		setProgress(false);
		return toast.warn("Password must be 6 character long!");
	}else if(confirmPassword == ""){
		setProgress(false);
		return toast.warn("Please enter your confirm password!");
	}else if(password != confirmPassword){
		setProgress(false);
		return toast.warn("Confirm password not matched!");
	}

	console.log("onResetPassword");


	const config = {
		header: {
		  "Content-Type": "application/json",
		},
	  };

	  try {
		//axios.post("/api/auth/signIn",fd,config);
		const { data } = await axios.post(
		  "/api/auth/resetPassword",
		  { 
			password:  password,
			resetToken: match.params.resetToken},
		  config
		);

		setProgress(false);

		if(!data.success){
			toast.error(data.message);
		}else{
			toast.success(data.message);
			history.push(`/sign-in`);
			//setSuccess(true);
			//toast.error(data.message);
		}
		

	  } catch (error) {
		setProgress(false);
		//setError(error.response.data.error);
		toast.error(error.response.data.error);
		setTimeout(() => {
		  //setError("");
		}, 5000);
	  }

	/* let response = await auth.sendPasswordResetEmail(email);

    if(response){
		setMainSent(true);
		toast("Password reset code sent on "+email);
		setProgress(false);
		return;
	}else{
		toast.error("Password reset code send failed!");
		setProgress(false);
	} */

  };


/*   const setNewPassword = async (e) => {
    e.preventDefault();
	setProgress(true);

	let response = await auth.confirmPasswordReset(code,password);
	if(response){
		toast.success("Password is changed!");
		setProgress(false);
		history.push(`/sign-in`);
		return;
	}else{
		//setMainSent(true);
		toast.success("Password reset failed!");
		setProgress(false);
		return;
	}
	
  }; */
 
  return (
	  <ResetPasswordContainer style={{ background: "#17556D" }}>
   <section
        className="user-login section bg-white"
        style={{ backgroundImage: "url(" + loginbg + ")" }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center p-2">
            <div className="col-md-4">
              <div className="card p-2" style={{ borderRadius: "8px" }}>
                <img className="card-img-top" src={loginBanner} alt="" />

                <div className="card-body">
                  <div className="mb-4">
                    <h3 className="login-title mb-1">Reset Password</h3>
                  </div>

                  <div className="content">

						{

success ?
						(<p>login</p>):(<Form onSubmit={onResetPassword}>

						
<Form.Group>
<Form.Control className="main" type="text" placeholder="New Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
</Form.Group>


						
<Form.Group>
<Form.Control className="main" type="text" placeholder="Confirm New Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
</Form.Group>

	{progress ? <div className="text-center"><Spinner animation="border" variant="primary" /></div> : 
<Button variant="primary" type="submit" className="btn btn-primary btn-block">
Reset Password
</Button>}



</Form>)}


<div className="new-account mt-2 text-center">
                                  <p className="p">

								  <Link className="text-primary" to="/forgot-password">Forgot Password</Link><br/>

								  Already have an account?{" "}
                                    <Link className="text-primary" to="/login">
                                      Login
                                    </Link>
                                    
                                  </p>
                                </div>

						
						</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

</ResetPasswordContainer>
);
};

export default ResetPasswordPage;
const profileTypeList = [
  {
    type: "business",
    name: "Business Card",
    icon: "fa fa-id-card",
    hint:"",
    background:"https://dashboard.tap1ce.com/media/bg-business.jpg",
  },
  {
    type: "spotify",
    name: "Spotify Card",
    icon: "fab fa-spotify",
    hint:"@spotify",
    inputType:"Username",
    background:"https://dashboard.tap1ce.com/media/bg-spotify.jpg"
  },
  {
    type: "instagram",
    name: "Instagram",
    icon: "ti-instagram",
    hint:"@instagram",
    inputType:"Username",
    background:"https://dashboard.tap1ce.com/media/bg-instagram.jpg"
  },
  {
    type: "portfolio",
    name: "Portfolio Card",
    icon: "ti-user",
    hint:"https://...",
    inputType:"Link",
    background:"https://dashboard.tap1ce.com/media/bg-portfolio.jpg"
  },
  {
    type: "linkedin",
    name: "LinkedIn Card",
    icon: "ti-linkedin",
    hint:"@linkedIn",
    inputType:"Username",
    background:"https://dashboard.tap1ce.com/media/bg-linkedin.jpg"
  },
  {
    type: "youtube",
    name: "YouTube Card",
    icon: "ti-youtube",
    hint:"@youtube",
    inputType:"Username",
    background:"https://dashboard.tap1ce.com/media/bg-youtube.jpg"
  },
  {
    type: "1link",
    name: "1 Link",
    icon: "ti-link",
    hint:"@1link",
    inputType:"Username",
    background:"https://dashboard.tap1ce.com/media/bg-youtube.jpg"
  },
 /*  {
    type: "virtual",
    name: "Virtual Profile",
    icon: "ti-user",
    hint:"virtual profile",
    inputType:"Link",
    background:"https://dashboard.tap1ce.com/media/bg-youtube.jpg"
  }, */
];

export default profileTypeList
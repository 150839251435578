import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../providers/use-auth.js";

import { withRouter, useLocation, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

import SignInPage from "../sign-in/sign-in";
import SignUpPage from "../sign-up/sign-up";

import { MyAccountContainer } from "./style";

import axiosInstance from "../../services/AxiosInstance";

import Resizer from "react-image-file-resizer";

import { Country, State, City } from "country-state-city";

import moment from "moment";

//import SignInPage from "../sign-in/sign-in";

import {
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  Tabs,
  TabContainer,
  TabContent,
  TabPane,
  Spinner,
  Card,
  Badge,
  Modal,
} from "react-bootstrap";

import { toast } from "react-toastify";

// Components
import CardItem from "../../components/card-item/card-item";
import OrderItem from "../../components/order-item/order-item";
import InvitationItem from "../../components/invitation-item/invitation-item";

//Actions
import { getAllCards } from "../../redux/actions/cardActions";

// Actions
import {
  getUser,
  clearUser,
  signIn,
  logout,
} from "../../redux/actions/authActions";

import profileTypeList from "../../utils/profile-type";

import PhoneInput from "react-phone-number-input";

import { exportToExcel } from "react-json-to-excel";

// const avatarImg =
//   "https://dashboard.tap1ce.com/media/avatar.png";
const logImg = "https://dashboard.tap1ce.com/media/logo.png";

const MyAccountPage = ({ props, match, history }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [profileImage, setProfileImage] = useState("");
  const [avatarImg, setAvatarImg] = useState("");

  const [progress, setProgress] = useState(true);

  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  const [tapCount, setTapCount] = useState(0);

  const isAuthenticated = useSelector((state) => state.isAuthenticated);

  const fireAuth = useAuth();

  const userSelector = useSelector((state) => state.auth);
  const { auth } = userSelector;

  const getAllCard = useSelector((state) => state.getAllCard);
  const { cards, allCardLoading, allCardError } = getAllCard;

  const [orders, setOrders] = useState([]);

  const [invitations, setInvitations] = useState([]);

  const [profileModal, setProfileModal] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [userName, setUserName] = useState("");
  const [userPoints, setUserPoints] = useState(0);

  let errorsObj = {
    name: "",
    phone: "",
    addLine1: "",
    addLine2: "",
    addCity: "",
    addState: "",
    addLandmark: "",
    addZipcode: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [addCity, setAddCity] = useState("");
  const [addState, setAddState] = useState("State");
  const [addCoutry, setAddCountry] = useState("United Arab Emirates");
  const [addLandmark, setAddLandmark] = useState("");
  const [addZipcode, setAddZipcode] = useState("");

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  //change password
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  useEffect(() => {
    getUser();

    const cc = Country.getAllCountries().find(
      (x) => x.name == addCoutry
    ).isoCode;

    setStates(State.getStatesOfCountry(cc));
    setCities(City.getCitiesOfCountry(cc));
  }, [dispatch]);

  useEffect(() => {
    const cc = Country.getAllCountries().find(
      (x) => x.name == addCoutry
    ).isoCode;

    setStates(State.getStatesOfCountry(cc));
    setCities(City.getCitiesOfCountry(cc));

    setAddCity("select");
    setAddState("select");
  }, [addCoutry]);

  useEffect(() => {
    const countries = Country.getAllCountries();

    const cc = countries.find((x) => x.name == addCoutry).isoCode ?? "";

    const state = states.find((x) => x.name == addState);

    //setStates(State.getStatesOfCountry(cc))
    setCities(City.getCitiesOfState(cc, state ? state.isoCode : ""));
  }, [addState]);

  useEffect(() => {
    //check if user is login
    if (auth) {
      // &&

      if (fireAuth.user) {
        console.log("auth token --->" + auth.token);
        dispatch(getAllCards());
        loadOrders();
        loadInvitations();
        setProgress(false);
        setLoggedIn(true);

        loadProfileDetails();

        /* if(auth.user){
       
          //setProfile(data.user);
          const profile = auth.user;
          setName(profile.name??"");
          setPhone(profile.phone??"");
          if(profile.address){
             setAddLine1(profile.address.addressLine1??"");
             setAddLine2(profile.address.addressLine2??"");
             setAddCity(profile.address.city??"");
             setAddState(profile.address.state??"");
             setAddZipcode(profile.address.zip??"");
          }
        
      } */
      } else {
        setProgress(false);
        setLoggedIn(false);
        setLoading(false);
        //window.location = "/login";
        //history.push("/login");
      }
    } else {
      setProgress(false);
      setLoggedIn(false);
      setLoading(false);
    }
  }, [fireAuth.user]);

  const loadProfileDetails = async () => {
    const { data } = await axiosInstance.get("auth/profile");

    if (data.user) {
      //setProfile(data.user);
      const profile = data.user;
      setName(profile.name ?? "");
      setUserName(profile.name ?? "");
      setPhone(profile.phone ?? "");
      setAvatarImg(profile.profile ?? "");
      setUserPoints(profile.points ?? 0);

      if (profile.address) {
        setAddLine1(profile.address.addressLine1 ?? "");
        setAddLine2(profile.address.addressLine2 ?? "");
        setAddCity(profile.address.city ?? "");
        setAddState(profile.address.state ?? "");
        setAddZipcode(profile.address.zip ?? "");
      }
    }

    setLoading(false);
    console.log("details data - " + JSON.stringify(data));
  };

  useEffect(() => {
    if (cards) {
      console.log("cards founds");

      var totalCount = 0;

      cards.map((card) => {
        totalCount = totalCount + card.taps;
      });

      setTapCount(totalCount);
    }

    if (allCardLoading) {
      console.log("allCardLoading");
    } else {
      console.log("allCardLoading false");
    }

    if (allCardError) {
      console.log("allCardError");
      toast.error(allCardError);
    }
  }, [cards, allCardLoading, allCardError]);

  const loadOrders = async () => {
    try {
      //axios.post("/api/auth/signIn",fd,config);
      const { data } = await axiosInstance.post("orders/history", {});

      if (data.status == true) {
        setOrders(data.orders);
        console.log("orders - " + JSON.stringify(data.orders));
      } else {
      }
    } catch (error) {
      //toast.error("something is wrong");
    }
  };

  const refreshAll = async () => {
    loadInvitations();
    dispatch(getAllCards());
  };

  const onLogout = () => {
    dispatch(logout());
    history.push('/login');
  };

  const loadInvitations = async () => {
    try {
      const { data } = await axiosInstance.get("corporate/pending-invitations");

      if (data.status == true) {
        setInvitations(data.invitations);
        console.log("invitations - " + JSON.stringify(data.invitations));
      }
    } catch (error) {
      //toast.error("something is wrong");
    }
  };

  const onSaveProfile = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (name === "") {
      errorObj.name = "Name is Required";
      error = true;
    }
    if (phone === "") {
      errorObj.phone = "Phone Number is Required";
      error = true;
    }

    if (addLine1 === "") {
      errorObj.addLine1 = "Address Line 1 is Required";
      error = true;
    }
    if (addLine2 === "") {
      errorObj.addLine2 = "Address Line 2 is Required";
      error = true;
    }
    if (addCity === "") {
      errorObj.addCity = "Address City is Required";
      error = true;
    }
    if (addState === "") {
      errorObj.addState = "Address State is Required";
      error = true;
    }
    if (addZipcode === "") {
      errorObj.addZipcode = "Address Pincode is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }
    console.log("no error on save");
    //save data

    const formData = {
      name: name,
      phone: phone,
      profile: profileImage,

      add_line_1: addLine1,
      add_line_2: addLine2,
      add_city: addCity,
      add_state: addState,
      add_zipcode: addZipcode,
    };

    console.log("form data - " + formData);

    const { data } = await axiosInstance.post("auth/update-profile", formData);

    if (data.status) {
      toast.success(data.message);
      setProfileModal(false);
      loadProfileDetails();
    } else {
      toast.error(data.message);
    }
  };

  const fileChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          150,
          150,
          "PNG",
          80,
          0,
          (uri) => {
            console.log(uri);
            //this.setState({ newImage: uri });
            setProfileImage(uri);
          },
          "base64",
          100,
          100
        );
      } catch (err) {
        console.log(err);
        setProfileImage("");
      }
    }
  };

  const fileClickHandler = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const upload = (e) => {
    e.preventDefault();
    document.getElementById("selectFile").click();
  };

  const onChangePassword = async (e) => {
    setNewPassword("");
    setOldPassword("");
    setChangePasswordModal(true);
  };

  const onChangePasswordSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    // if (oldPassword === "") {
    //   errorObj.oldPassword = "Old Password is Required";
    //   error = true;
    // }

    if (newPassword === "") {
      errorObj.newPassword = "Phone Number is Required";
      error = true;
    } else if (newPassword.length <= 7) {
      errorObj.newPassword = "New Password is must be 8 character";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }

    setChangePasswordLoading(true);
    const response = await fireAuth.changePassword(newPassword);

    if (response.status) {
      toast.success("Password Changed!");
      setChangePasswordModal(false);
    } else {
      toast.error(response.message);
    }

    setChangePasswordLoading(false);
  };

  const onConnectionExport = async () => {
    //exportToExcel
    const { data } = await axiosInstance.post("cards/connections", {});
    if (data.status) {
      const connections = data.connections.map((x, i) => {
        return {
          NO: i + 1,
          NAME: x.name,
          PHONE_NO: x.phone,
          EMAIL: x.email,
          COMPANY: x.company,
          ABOUT: x.about,
          DATE: moment(new Date(x.createdAt)).format("DD-MM-YYYY hh:mm A"),
        };
      });

      exportToExcel(
        connections,
        `MY CONNECTIONS - ${moment().format("DD-MM-YYYY h:mm")}`
      );
    } else {
      toast.error(data.message);
    }
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={changePasswordModal}
        onHide={setChangePasswordModal}
      >
        <div className="" role="document">
          <div className="content">
            <Form onSubmit={onChangePasswordSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Change Password</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setChangePasswordModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                {/* <Form.Group controlId="formPassword">
                   <label className="mb-2 required-field">
                     <strong>Old Password</strong>
                   </label>
                   <Form.Control
                     className="main"
                     type="password"
                     placeholder="Old Password"
                     value={oldPassword}
                     onChange={(e) => setOldPassword(e.target.value)}
                     required
                   />

{errors.oldPassword && (
                       <div className="text-danger fs-12">{errors.oldPassword}</div>
                     )}

                 </Form.Group> */}

                <Form.Group controlId="formPassword">
                  <label className="mb-2 required-field">
                    <strong>New Password</strong>
                  </label>
                  <Form.Control
                    className="main"
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />

                  {errors.newPassword && (
                    <div className="text-danger fs-12">
                      {errors.newPassword}
                    </div>
                  )}
                </Form.Group>

                <div className="modal-footer">
                  <div className="align-self-center mx-auto">
                    {changePasswordLoading ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Change
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal
        size="lg"
        className="modal fade"
        show={profileModal}
        onHide={setProfileModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSaveProfile}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit Profile</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setProfileModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-row">
                  <Col lg={4} className="text-center">
                    <img
                      onClick={upload}
                      className="img img-fluid my-4"
                      style={{
                        width: "100px",
                        height: "100px",
                        "border-radius": "50%",
                      }}
                      src={
                        profileImage != ""
                          ? profileImage
                          : "https://dashboard.tap1ce.com/media/avatar.png"
                      }
                    ></img>
                    <input
                      id="selectFile"
                      type="file"
                      hidden
                      onChange={fileChangedHandler}
                      onClick={fileClickHandler}
                    />
                  </Col>

                  <Col lg={8} className="">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        className="form-control"
                      />
                      {errors.name && (
                        <div className="text-danger fs-12">{errors.name}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      {/* <input
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Phone Number"
                      className="form-control"
                    /> */}

                      <PhoneInput
                        defaultCountry="IN"
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={setPhone}
                      />

                      {errors.phone && (
                        <div className="text-danger fs-12">{errors.phone}</div>
                      )}
                    </div>
                  </Col>
                </div>

                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    value={addLine1}
                    onChange={(e) => setAddLine1(e.target.value)}
                    placeholder="e.g. 1234 Main St"
                    className="form-control"
                  />
                  {errors.addLine1 && (
                    <div className="text-danger fs-12">{errors.addLine1}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Address 2</label>
                  <input
                    type="text"
                    value={addLine2}
                    onChange={(e) => setAddLine2(e.target.value)}
                    placeholder="e.g. Apartment, studio, or floor"
                    className="form-control"
                  />
                  {errors.addLine2 && (
                    <div className="text-danger fs-12">{errors.addLine2}</div>
                  )}
                </div>
                <div className="form-row">
                  <div className="form-group col-md-3">
                    <label>City</label>
                    {/* <input
                      value={addCity}
                      onChange={(e) => setAddCity(e.target.value)}
                      type="text"
                      className="form-control"
                    /> */}

                    <Form.Control
                      as="select"
                      className="main"
                      value={addCity}
                      onChange={(e) => {
                        console.log("e.target.value", e.target.value);
                        setAddCity(e.target.value);
                      }}
                    >
                      <option value={"select"}>Select</option>
                      {cities.map((x, i) => (
                        <option key={"s-" + i} value={x.name}>
                          {x.name}
                        </option>
                      ))}
                    </Form.Control>

                    {errors.addCity && (
                      <div className="text-danger fs-12">{errors.addCity}</div>
                    )}
                  </div>
                  <div className="form-group col-md-3">
                    <label>State</label>

                    <Form.Control
                      as="select"
                      className="main"
                      value={addState}
                      onChange={(e) => {
                        console.log("e.target.value", e.target.value);
                        setAddState(e.target.value);
                      }}
                    >
                      <option value={"select"}>Select</option>
                      {states.map((x, i) => (
                        <option key={"s-" + i} value={x.name}>
                          {x.name}
                        </option>
                      ))}
                    </Form.Control>

                    {errors.addState && (
                      <div className="text-danger fs-12">{errors.addState}</div>
                    )}
                  </div>

                  <div className="form-group col-md-3">
                    <label>Country</label>

                    <Form.Control
                      as="select"
                      className="main"
                      value={addCoutry}
                      onChange={(e) => {
                        console.log("e.target.value", e.target.value);
                        setAddCountry(e.target.value);
                      }}
                    >
                      {Country.getAllCountries().map((x, i) => (
                        <option key={"c-" + i} value={x.name}>
                          {x.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>

                  <div className="form-group col-md-3">
                    <label>Pincode</label>
                    <input
                      type="text"
                      value={addZipcode}
                      onChange={(e) => setAddZipcode(e.target.value)}
                      className="form-control py-4"
                    />
                    {errors.addZipcode && (
                      <div className="text-danger fs-12">
                        {errors.addZipcode}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setProfileModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>

                <button className="btn btn-primary" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {loading ? (
        <div className="loader-box">
          <div className="col text-center">
            <div className="loader"></div>
          </div>
        </div>
      ) : progress ? (
        <div className="loader-box">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : loggedIn ? (
        <MyAccountContainer>
          <section className="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="content-heading clearfix media text-left">
                    <div className="text-center pull-left">
                      <img
                        src={
                          avatarImg != ""
                            ? avatarImg
                            : "https://dashboard.tap1ce.com/media/avatar.png"
                        }
                        className="avatar mt-auto"
                        alt="Avatar"
                      />
                      <br />
                      <div className="text-center mt-4">
                        <Link
                          className="btn btn-primary btn-sm"
                          role="button"
                          onClick={() => {
                            setProfileImage(avatarImg);
                            setProfileModal(true);
                          }}
                        >
                          Edit
                        </Link>
                      </div>
                    </div>
                    <div className="px-4">
                      <h3 className="hello">Hello,</h3>
                      <h2 className="name">
                        {userName}
                        {/* {auth.user ? auth.user.name : "User"} */}
                      </h2>
                    </div>
                  </div>

                  {/* <Button className="btn btn-light my-2" onClick={(e)=>onChangePassword()}>Change Password</Button> */}
                </div>

                <div className="col-lg-8">
                  <div className="row justify-content-end">
                    <div className="col-lg-4 mb-2">
                      <div className="small-card blue-card-bg p-2">
                        <div className="content">
                          <h3 className="title font-weight-bold">
                            Numbers Of
                            <br />
                            Taps
                          </h3>
                          <h1 className="msg font-weight-bold">{tapCount}</h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-2">
                      <div className="small-card light-card-bg p-2">
                        <div className="content">
                          <img src={logImg} className="img-fluid pr-5" />

                          <h3 className="title font-weight-bold">Points</h3>
                          <h1 className="msg font-weight-bold">{userPoints}</h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-end mt-2">
                    <div className="col-lg-12 text-right">
                      <Button
                        className="btn btn-primary "
                        onClick={(e) => onChangePassword()}
                      >
                        Change Password
                      </Button>

                      <Button
                        className="btn btn-primary ml-2"
                        onClick={(e) => onConnectionExport()}
                      >
                        My Connections
                      </Button>

                      <Button
                        variant="outline-danger"
                        className="ml-2"
                        onClick={(e) => onLogout()}
                      >
                        Logout
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="row pb-5 mb-4 mt-4">
                  {!allCardLoading && cards ? (
                    cards.map((card, i) => (
                      <div
                        key={card._id}
                        className="col-lg-3 col-md-6 mb-4 mb-lg-0"
                      >
                        <CardItem
                          key={card._id}
                          card={card}
                          isEven={i == 0 || i % 2 == 0}
                        ></CardItem>
                      </div>
                    ))
                  ) : (
                    <div>Loading cards...</div>
                  )}
                </div>
              </div>

              <div className="row pb-5 mb-4 mt-4">
                <div className="col-lg-12 my-2">
                  <h4 className="h4 font-weight-bold text-dark">
                    Pending Invitations
                  </h4>
                </div>

                {invitations.length > 0 ? (
                  invitations.map((invitation, i) => (
                    <div
                      key={invitation._id}
                      className="col-lg-4 col-md-6 mb-4 mb-lg-0"
                    >
                      <InvitationItem
                        key={invitation._id}
                        invitation={invitation}
                        onChange={() => refreshAll()}
                      ></InvitationItem>
                    </div>
                  ))
                ) : (
                  <div className="col-lg-12 text-center py-4">
                    <p>No corporate Invitation</p>
                    <br />
                  </div>
                )}

                <div className="col-lg-12 my-2">
                  <h4 className="h4 font-weight-bold text-dark">My Orders</h4>
                </div>

                {orders ? (
                  orders.map((order, i) => (
                    <div
                      key={order._id}
                      className="col-lg-6 col-md-6 mb-4 mb-lg-0"
                    >
                      <OrderItem key={order._id} order={order}></OrderItem>
                    </div>
                  ))
                ) : (
                  <div>Loading orders...</div>
                )}
              </div>

              {/*    <Tab.Container
              id="myTab"
             
            >
              <Row>
                <Col sm={3}>
                  <Nav

                    variant="pills"
                    className="flex-column bg-white shadow-sm text-center bg-light border-0 rounded"
                  
                  >
                    <Nav.Item className="nav-item flex-sm-fill my-2">
                      <Nav.Link
                        className="border-0 text-uppercase font-weight-bold"
                        eventKey="my-account"
                      >
                        My Account
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="nav-item flex-sm-fill my-2">
                      <Nav.Link
                        className="border-0 text-uppercase font-weight-bold"
                        eventKey="orders"
                      >
                        Orders
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="nav-item flex-sm-fill my-2">
                      <Nav.Link
                        className="border-0 text-uppercase font-weight-bold"
                        eventKey="cards"
                      >
                        Cards
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>


                <Col sm={9}>
                  <Tab.Content className="p-4 bg-white shadow-sm rounded h-100 mt-4 mt-md-0">
                    <Tab.Pane eventKey="my-account">
                      <h1>Hi, { auth.user ? auth.user.name : ""}</h1>
                      <p>Please click <Link  className="text-primary"><b>here</b></Link> to view and activate your profiles.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="orders">
                      <h1>Orders</h1>

                      <Table responsive striped bordered hover>
  <thead>
    <tr>
      <th>#ID</th>
      <th>Date</th>
      <th>Status</th>
      <th>Total</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>

    {

orders.map((order) => (
  <tr key={order._id}>
    <td>{order._id}</td>
    <td>{order.createdAt}</td>
    <td>{order.paymentStatus == "unpaid" ? "Unpaid":order.status}</td>
    <td>{order.currency} {order.orderTotal} for {order.items.length} Items</td>
    <td><Button>View</Button></td>
</tr>
))

    }
   
   
  </tbody>
</Table>

                    </Tab.Pane>

                    <Tab.Pane eventKey="cards">
                      <h1>cards</h1>

                      <div className="row pb-5 mb-4">
                        {!allCardLoading && cards ? (
                          cards.map((card) => (
                            <Card key={card._id} card={card}></Card>
                          ))
                        ) : (
                          <div>Loading cards...</div>
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container> */}
            </div>
          </section>
        </MyAccountContainer>
      ) : (
        // <div className="loader-box">
        //   <Spinner animation="border" variant="primary" />
        // </div>

      
          <div className="container text-center">
            <div className="row h-100 ">
              <div className="col-lg-12">
                <div className="section-title">
                  <h2>My Account</h2>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 text-center">
                {/*   <p>
                  Don't have an account? <Link to="/sign-up">Sign Up</Link>
                </p>
                <br /> */}

                <p className="lead">
                  <Link
                    className="btn btn-primary btn-sm mr-2"
                    to="/login"
                    role="button"
                  >
                    Sign In
                  </Link>

                  <Link
                    className="btn btn-primary btn-sm"
                    to="/sign-up"
                    role="button"
                  >
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        
      )}
    </>
  );
};

//export default withRouter(MyAccountPage)
export default MyAccountPage;

export const isAuthenticated = (state) => {
    
    //const authString = localStorage.getItem('auth');
    //const authDetails = JSON.parse(authString);
    //console.log("isAuthenticated authData - "+JSON.stringify(state.auth));
    if (state.auth.auth){
        console.log("isAuthenticated - true");
        return true;
    }else{
        console.log("isAuthenticated - false");
        return false;
    }
};

import * as actionTypes from "../constants/contactConstants";

export const contactReducer = (state = {isSent : false}, action) => {
  switch (action.type) {
    case actionTypes.CONTACT_REQUEST:
      return {
        ...state,
        contactLoading: true,
      };
    case actionTypes.CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        contactLoading: false,
        isSent:action.payload
      };
    case actionTypes.CONTACT_REQUEST_FAIL:
      return {
        ...state,
        contactLoading: false,
        sendError: action.payload,
      };
      case actionTypes.CONTACT_REQUEST_RESET:
        return {
          contactLoading: false,
          isSent:false
        };
    default:
      return state;
  }
};



import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useState, useEffect } from "react";

import { ListGroup,Card,Badge  } from "react-bootstrap";

import profileTypeList from "../../utils/profile-type";

import {OrderItemContainer} from "./style";

import { format } from "date-fns";

const cardImg = "https://dashboard.tap1ce.com/media/login-banner.jpg";

const OrderItem = ({ order}) => {




  function productImg(){

    console.log("product Image - "+JSON.stringify(order.items[0]));

    if (order.items.length === 0 || order.items[0].productId === undefined || order.items[0].productId === null || order.items[0].productId === "") {
      return cardImg; 
    }else{

    const variation = order.items[0].productId.variations.find(
      x => x._id === order.items[0].variationId
    );
    console.log("product variation - "+JSON.stringify(variation));
      return variation.dimensions.template.frontImage;
    }
  }

  function productName(){
    if (order.items.length === 0 || order.items[0].productId === undefined || order.items[0].productId === null || order.items[0].productId === "") {
      return "Tap1ce Card"; 
    }else{

      var name = "";
     order.items.map((item,i) => {

      if(i != 0){
        name = name + ", ";
      }

      name = name + item.productId.name+" - "+order.currency+" "+item.productId.salePrice+" x"+item.quantity
     });
     return name;
    }
  }

  function orderStatus(){
    if(order.paymentStatus === "paid" && order.status === "Processing"){
      return (<Badge pill bg="success" className="py-2 px-4 text-white">
          {order.status}
      </Badge>);
    }else if(order.status == "cancelled"){
      return (<Badge pill bg="danger" className="py-2 px-4 text-white">
          {order.status}
      </Badge>);
    }else if(order.paymentStatus === "unpaid"){
      return (<Badge pill bg="warning" className="py-2 px-4 text-white">
          {"Unpaid"}
      </Badge>);
    }else{
      return (<Badge pill bg="warning" className="py-2 px-4 text-white">
          {order.status}
      </Badge>);
    }
  }
  
  return (
    <OrderItemContainer className="mt-4">
   

      <Card className="border-0">

        <Card.Body>

          <p><small>#{order.id}</small></p>

          <div className="order-status text-right">

          {/* <Badge pill bg="success" className="py-2 px-4 text-white">
            Paid
          </Badge> */}
          {orderStatus()}

          <p className="order-date text-grey mt-2">{ format(new Date(order.createdAt), "dd/MM/yyyy H:mma")}</p>

          </div>

          <div className="row mt-4">

          <div className="col-4">

              <img src={productImg()} className="img-fluid product-image" />
          </div>
          <div className="col-8 text-left my-auto py-2">

          <h6 className="h6">{productName()}</h6>
          <p>{order.currency} {order.orderTotal}</p>

          </div>

          </div>

        </Card.Body>

      </Card>
   
    </OrderItemContainer>
  );
};

export default OrderItem;

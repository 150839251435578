import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Reducers
import { cartReducer } from "./reducers/cartReducers";

import {
  getProductsReducer,
  getProductDetailsReducer,
} from "./reducers/productReducers";

import {
  signUpReducer,
  signInReducer,
  userReducer
} from "./reducers/authReducers";

import {
  getAllCardReducer,
  getCardDetailsReducer,

  getAllProfileReducer,
  getProfileDetailsReducer,

  addProfileReducer,
  activeProfileReducer,
  saveProfileReducer
} from "./reducers/cardReducers";

import { checkoutReducer,placeOrderReducer,razorpayVerificationReducer } from "./reducers/checkoutReducers";

import { contactReducer } from "./reducers/contactReducers";

const reducer = combineReducers({
  //cart
  cart: cartReducer,
  checkout:checkoutReducer,
  getProducts: getProductsReducer,
  getProductDetails: getProductDetailsReducer,

  //auth
  auth: userReducer,
  
  signUp:signUpReducer,
  signIn:signInReducer,
  placeOrder:placeOrderReducer,
  razorpayVerification:razorpayVerificationReducer,

  //card
  getAllCard:getAllCardReducer,
  getCardDetails:getCardDetailsReducer,

  getAllProfile:getAllProfileReducer,
  getProfileDetails:getProfileDetailsReducer,

  addProfile:addProfileReducer,
  activeProfile:activeProfileReducer,
  saveProfile:saveProfileReducer,

  contact:contactReducer
});

const middleware = [thunk];

const cartItemsInLocalStorage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

  const checkoutBasicDetailsInLocalStorage = localStorage.getItem("checkoutBasicDetails")
  ? JSON.parse(localStorage.getItem("checkoutBasicDetails"))
  : {};

  const checkoutFullDetailsInLocalStorage = localStorage.getItem("checkoutFullDetails")
  ? JSON.parse(localStorage.getItem("checkoutFullDetails"))
  : {};

  //user
  const userLocalStorage = localStorage.getItem("auth")
  ? JSON.parse(localStorage.getItem("auth"))
  : {};


  


const INITIAL_STATE = {
 
  cart: {
    cartItems: cartItemsInLocalStorage,
  },
  checkout:{
    basicDetails:checkoutBasicDetailsInLocalStorage,
    fullDetails:checkoutFullDetailsInLocalStorage
  },
  auth:userLocalStorage,
};

const store = createStore(
  reducer,
  INITIAL_STATE,
  //composeEnhancers(middleware)
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

//export const store = createStore(reducers,  composeEnhancers(middleware));
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useState, useEffect } from "react";

import { ListGroup, Card } from "react-bootstrap";

import profileTypeList from "../../utils/profile-type";

import { CardItemContainer } from "./style";

import { format } from "date-fns";


const cardBG = "https://dashboard.tap1ce.com/media/profile-bg-1.jpg";
const cardBG2 = "https://dashboard.tap1ce.com/media/profile-bg-2.jpg";

const CardItem = ({ card, isEven }) => {
  const [profileType, setProfileType] = useState(-1);

  useEffect(() => {
    //console.log("state - "+state);
    //console.log("state - "+JSON.stringify(state));

    const profileTypeIndex = profileTypeList.findIndex((x) =>
      card.activeProfile != null ? x.type === card.activeProfile.type : false
    );

    if (profileTypeIndex != -1) {

      if(profileTypeIndex == 6){
        setProfileType(0);
      }else{
        setProfileType(profileTypeIndex);
      }
    }else{
      setProfileType(0);
    }
  }, []);

  function fullName() {
    if (profileType == -1) {
      return "";
    }

    if (card.activeProfile) {
      var name = card.activeProfile.name;

      if (
        !(card.activeProfile.namePrefix === "undefined") &&
        card.activeProfile.namePrefix
      ) {
        name = card.activeProfile.namePrefix + " " + name;
      }
      return name;
    } else {
      return "";
    }
  }

  // if (card.activeProfile.namePrefix === "undefined") {
  //   card.activeProfile.namePrefix = "";
  // }

  return (
    <CardItemContainer className="mt-2">
      <Link to={"/cards/" + card.id}>
        {profileType == -1 || card.activeProfile == null ? (
           <div>
           <Card>
             <Card.Img variant="top" src={isEven ? cardBG2 : cardBG} />

             <div
               className={
                 "card-content text-left "
               }
             >
               <h2
                 className={
                   "h6 font-weight-bold " +
                   (isEven ? "text-grey" : "text-white") +" no-profile-active"
                 }
               >
               No Profile Active
               </h2>
               
             </div>

             {/* <div className="card-expire"><p className={(isEven ? "text-grey" : "text-white")}>{card.expiredAt? "Expire "+format(new Date(card.expiredAt), "dd/MM/yyyy"):""}</p> </div>
 */}
           </Card>

           <div className="row py-2">
             <div className="col">
               <Link
                 to={"/cards/" + card.id}
                 className="btn btn-sm btn-outline-light btn-block text-dark"
               >
                 View Profiles
               </Link>
             </div>
             <div className="col">
               <Link className="btn btn-sm btn-primary btn-block">Share</Link>
             </div>
           </div>
         </div>
        ) : (
          <div>
            <Card className="offer-default">

              {card.isCorporate?(
              <div className="tag">
              <div class="shape">
                <div class="shape-text">
                  corporate								
                </div>
              </div>
              </div>):(<>
                {/* <div className="card-expire"><p className={(isEven ? "text-black" : "text-white")}>{card.expiredAt? "Expire "+format(new Date(card.expiredAt), "dd/MM/yyyy"):""}</p> </div>
 */}
              </>)}
             
              

              <Card.Img variant="top" src={isEven ? cardBG2 : cardBG} />

              <div className={"card-taps text-left"}>
                <h3
                  className={
                    "mb-0 title font-weight-bold " +
                    (isEven ? "text-grey" : "text-white")
                  }
                >
                  {card.taps ? card.taps : "0"} Taps{", "}{card.uniqueTaps ? card.uniqueTaps : "0"} Unique Taps
                  <br/>
                  {card.activeProfile.profileName
                    ? card.activeProfile.profileName
                    : ""}

                </h3>
                
              </div>

              <div
                className={
                  "card-content text-left " +
                  (isEven ? "text-grey" : "text-white")
                }
              >
                <h2
                  className={
                    "h6 font-weight-bold mb-0 " +
                    (isEven ? "text-grey" : "text-white")
                  }
                >
                  <i class={profileTypeList[profileType].icon}></i>
                  &nbsp;
                  {profileTypeList[profileType].type === "business"
                    ? fullName()
                    : profileTypeList[profileType].name}
                </h2>
                <p
                  className={
                    "text-small sort-link marquee " +
                    (isEven ? "text-grey" : "text-white")
                  }
                >
                  <span>
                    {profileTypeList[profileType].type === "business"
                      ? card.activeProfile.company
                      : card.activeProfile.link}
                  </span>
                </p>
              </div>
            </Card>

            <div className="row py-2">
              <div className="col">
                <Link
                  to={"/cards/" + card.id}
                  className="btn btn-sm btn-outline-light btn-block text-dark"
                >
                  View Profiles
                </Link>
              </div>
              <div className="col">
                <Link className="btn btn-sm btn-primary btn-block">Share</Link>
              </div>
            </div>
          </div>
        )}

      </Link>
    </CardItemContainer>
  );
};

export default CardItem;

import axios from 'axios';
import store  from '../redux/store';

const axiosInstance = axios.create({
    baseURL: `/api/`,
});

axiosInstance.interceptors.request.use((config) => {

    const state = store.getState();
    const token = state.auth.auth.token;

    const authString = localStorage.getItem('auth');
    const authDetails = JSON.parse(authString);

    console.log("axiosInstance authData - "+JSON.stringify(state.auth));
    console.log("axiosInstance auth token - "+token);
    
    config.headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      };
    config.proxy = {
        host: 'localhost',
        port: 5000
    }

	console.log("axiosInstance config ==> "+JSON.stringify(config));
    return config;

});

export default axiosInstance;

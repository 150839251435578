import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useState, useEffect } from "react";

import { ListGroup,Card,Badge  } from "react-bootstrap";

import profileTypeList from "../../utils/profile-type";

import {InvitationItemContainer} from "./style";

import { format } from "date-fns";


import axiosInstance from "../../services/AxiosInstance";
import { toast } from "react-toastify";

const InvitationItem = ({ invitation,onChange}) => {


  const accept = async() => {

    try {
      
      const { data } = await axiosInstance.post(
        "corporate/accept-invitation",
        {
          invitation_id:invitation.id
        },
      );

      if(data.status == true){
        onChange();
        toast.success(data.message);
        console.log("invitation accepted");
      }else{
        toast.error(data.message);
        console.log("invitation accept failed");
      }

    } catch (error) {
      toast.error("something is wrong");
    }

    
  }

  
  const reject = async() => {
    try {
      
      const { data } = await axiosInstance.post(
        "corporate/reject-invitation",
        {
          invitation_id:invitation.id
        },
      );

      if(data.status == true){
        onChange();
        toast.success(data.message);
        console.log("invitation rejected");
      }else{
        toast.error(data.message);
        console.log("invitation reject failed");
      }

    } catch (error) {
      toast.error("something is wrong");
    }
  }
  
  return (
    <InvitationItemContainer className="mt-4">
   

      <Card className="border-0">

        <Card.Body>

        
          <div className="text-left py-2">

           {/* <Badge pill bg="success" className="py-2 px-4 text-white">
            From {invitation.userId.name}
          </Badge> */}
<p className="invite-date text-grey mt-2">{ format(new Date(invitation.createdAt), "dd/MM/yyyy H:mma")}</p>

{invitation.userId.email} has invited you to as an employee member on the corporate <strong className="text-primary">{invitation.userId.name}</strong>

          
          </div>

          <div className="row py-2">
              <div className="col">
                <Link
                  onClick={(e)=>accept()}
                  className="btn btn-sm btn-success btn-block text-dark"
                >
                  Accept
                </Link>
              </div>
              <div className="col">
                <Link
                onClick={(e)=>reject()}
                className="btn btn-sm btn-danger btn-block">Reject</Link>
              </div>
            </div>

        </Card.Body>

      </Card>
   
    </InvitationItemContainer>
  );
};

export default InvitationItem;
